.about-us-outer {
    margin: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-us-outer img {
    width: 40vw;
}

.about-us-outer p {
    margin: 1rem;
    width: 50vw;
}

@media screen and (max-width: 1000px) {
    .about-us-outer {
        flex-direction: column;
        align-items: center;
    }
    
    .about-us-outer p {
        width: 80vw;
    }
}

@media screen and (max-width: 768px) {
    .about-us-outer img {
        width: 60vw;
    }
    
    .about-us-outer p {
        width: 80vw;
    }
}

@media screen and (max-width: 450px) {
    .about-us-outer img {
        width: 80vw;
    }
    
    .about-us-outer p {
        width: 80vw;
    }
}
