.Section2{
    margin-top: 100px;
    margin-left: 92px;
    margin-right: 92px;
}

.section2_1{
   flex: 1;
    margin-top: 30px;
}

.section2_1_heading1{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #FF5601;
    text-align: left;
}

.section2_1_heading2{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: #131543;
    text-align: left;
    padding-right: 40px;
}

.section2_1_heading3{
    font-family: open-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #646471;
    text-align: left;
}

.section2_2{
    flex-wrap: wrap;
    margin-left: 15px;
    flex: 3;
    justify-content: end;
}

.section2_2_1{
    padding: 30px;
    width: 390px;
    position: relative;
}

.section2_2_1_heading1{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #131543;
    text-align: left;
}

.section2_2_1_heading2{
    font-family: open-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #646471;
    text-align: left;
}

.section2_2_1_box{
    position: absolute;
    top: 60px;
    margin-left: 30px;
}

.section2_2_1_box_heading1{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 38px;
    text-align: left;
    color: #012346;
}

.section2_2_1_box_heading2{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    text-align: left;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    color: #012346;
}

/* @media screen and (max-width: 885px){
    .Section2{
        flex-direction: column !important;
        align-items: center !important;
    }
} */

@media screen and (max-width: 700px){
    .Section2{
        flex-direction: column;
    }
}