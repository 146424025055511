.Section4{
    background: url(https://nps-storage.s3.ap-south-1.amazonaws.com/test/Request+free+revewi.png) no-repeat center center;
    background-size: cover;
    height: 850px;
    justify-content: space-around;
    /* margin-top: 100px; */
    mix-blend-mode: multiply;
    /* opacity: 0.5; */
    color: #FFFFFF;
    padding-right: 15px;
}

.Section4_details{
    background: url(https://nps-storage.s3.ap-south-1.amazonaws.com/test/Request+free+revewi.png) no-repeat center center;
    background-size: cover;
    height: 650px;
    color: #FFFFFF;
    padding-right: 15px;
}

.Section4_1{
    width: 600px;    
    margin-top: 70px;
    text-align: left;
}

.Section4_1_heading1{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    color: #FFFFFF;
    text-align: left;
    margin-top: 30px;
    padding-right: 130px;
    margin-bottom: 0.5rem;
}

.Section4_1_heading2{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 0.5rem;
}

.Section4_1_heading3{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 0.5rem;
}

.Section4_1_heading4{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 64px;
    color: #FFFFFF;
    text-align: left;
    margin-top: 85px;
    margin-bottom: 0.5rem;
}

.Section4_1_heading5{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 0.5rem;
}

.Section4_2{
    width: 551px;
    height: 670px;
    background-color: #FFFFFF;
    padding: 42px 28px 36px 28px;
}

.Section4_2_details{
    width: 551px;
    height: 710px;
    background-color: #FFFFFF;
    padding: 42px 28px 36px 28px;
    position: absolute;
    top: 170px;
    right: 80px;
}

.Section4_2_details_about{
    height: 625px;
}

.Section4_2_heding1{
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    color: #0F2B61;
    text-align: left;
    padding-right: 135px;
}

.Section4_2_inputContact{
    width: 240px;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #C5CEDF;
    border-radius: 5px;
    margin-top: 15px;
    padding-left: 20px;
    width: 100%;
    height: 50px;
}

.Section4_2_input{
    width: 240px;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #C5CEDF;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-left: 20px;
}

.Section4_2_inputTop{
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #C5CEDF;
    border-radius: 5px;
    padding-left: 20px;
}

.Section4_2_inputTextArea{
    width: 240px;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #C5CEDF;
    border-radius: 5px;
    padding-left: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.Section4_2_inputbox{
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
}

.messbox{
    width: 495px;
    height: 214px;
    padding-top: 24px;
}

.Section4_2_form_button{
    width: 100%;
    height: 80px;
    background: #FF5601;
    border-radius: 5px;
    border: 0px;
    font-family: liberation-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

#submit-success-id {
    margin-bottom: .5rem;
    margin-top: .5rem;
    color: #0f2b61;
    font-weight: 900;
    font-size: 20px;
    display: none;
}

.formsubmit_success{
    width: 100%;
}

@media screen and (max-width: 500px){
    .Section4, .Section4_details{
        height: auto !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .Section4_1{  
        width: auto !important; 
        padding-left: 2%;
        padding-right: 2%;
    }

    .Section4_2{
        height: auto !important;
    }

    .Section4_2_details{
        height: auto !important;
        position: relative;
        top: 0px;
        right: 0px;
    }

    .Section4_2{
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      width: 85% !important;
    }

    .Section4_2_details{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        width: 85% !important;
        position: relative;
        top: 0px;
        right: 0px;
    }

    .Section4_2_heding1{
        font-size: 35px !important;
        padding-right: 0px !important;
    }

}



@media screen and ((max-width: 1200px) and (min-width:501px)){
    .Section4_2{
        width: 476px !important;
    }
    .Section4_2_details{
        width: 476px !important;
        right: 5px;
        height: auto;
    }
    .Section4_2_heding1 {
        font-size: 45px !important;
        padding-right: 0;
    }
}

@media screen and (max-width: 1100px){
    .Section4_1{
        width: 455px !important;
    }
    .Section4_1_heading1{
        font-size: 51px !important;
    }

    .Section4_1_heading2{
        font-size: 20px !important;
    }

    .Section4_1_heading3{
        font-size: 20px !important;
    }
    .Section4_1_heading4{
        margin-top: 53px !important; 
        font-size: 29px !important;
    }

    .Section4_1_heading5{
        font-size: 15px !important;
    }

    

    
}

@media screen and (max-width: 980px){
    .Section4, .Section4_details{
        height: auto !important;
        flex-direction: column !important;
        align-items: center !important;
        padding-right: 0px !important;
    }

    .Section4_2{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .Section4_2_details{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;
        top: 0px;
        right: 0px;
    }

    .Section4_1{
        width: auto !important;
        padding-left: 0px;
        padding-right: 15px;
        margin-left: 33px;
    }


    .Section4_1_heading1{
        text-align: left !important;
        padding-right: 0px !important;
    }
    .Section4_1_heading2{
        text-align: left !important;
    }
    .Section4_1_heading3{
        text-align: left !important;
    }
    .Section4_1_heading4{
        text-align: left !important;
    }

    .Section4_1_heading5{
        text-align: left !important;
    }

    
    .Section4_2{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }

      .Section4_2_details{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;
        top: 0px;
        right: 0px;
    }

}


button:disabled {
    background: #f8cbb4 !important;
}


.emailError {
    display: none;
    color: red;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
